import { Component } from '@angular/core';
import { GetDataService } from './get-data.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService} from './language.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    lang;

    subscription:Subscription;

    constructor( private DataService: GetDataService, public translate: TranslateService, public LangService: LanguageService) {
        translate.addLangs(['en', 'zh']);
        translate.setDefaultLang('zh');

        const browserLang = translate.getBrowserLang();
        translate.use('zh');
        translate.use(browserLang.match(/en|zh/) ? browserLang : 'en');

        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item)

    }

    changeLang (lang: string){
        this.LangService.changeLang()
        if ( this.translate.currentLang == 'en') {
            this.translate.use('zh');
        } else {
            this.translate.use('en');
        }
    }

    ngAfterViewInit() {
    }
}
