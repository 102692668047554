import { Component, Injectable, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private _navItemSource = new BehaviorSubject<string>('zh');

  navItem$ = this._navItemSource.asObservable();

  changeNav(language) {
    this._navItemSource.next(language);
  }

  changeLang() {
    if (this.translate.currentLang === 'en') {
      this.changeNav('zh')
    } else {
      this.changeNav('en')
    }
  }

  constructor( private translate: TranslateService) { }
}
