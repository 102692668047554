import { Component, OnInit } from '@angular/core';
import { GetDataService } from '../get-data.service';

@Component({
  selector: 'app-recruit-cakeshop',
  templateUrl: './recruit-cakeshop.component.html',
  styleUrls: ['./recruit-cakeshop.component.css']
})
export class RecruitCakeshopComponent implements OnInit {

  constructor( private DataService: GetDataService ) { }
  
  all_cake_shop_jobs;
  
  ngOnInit() {
    this.DataService.get_specific_cat_jobs({category_id: 'CAKESH'}).subscribe( data => {  
      this.all_cake_shop_jobs = data;
    })
  }
}
