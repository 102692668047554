import { Component, OnInit } from '@angular/core';

// Language related
import { LanguageService} from '../language.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { IpServiceService } from '../ip-service.service';
import { GetDataService } from '../get-data.service';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
    
    ipAddress:string;
    all_contacts;
    ocf;
    csf;
    
    subscription:Subscription;
    lang;
    
    constructor( private DataService: GetDataService, public LangService: LanguageService, private translate: TranslateService,  private ip:IpServiceService  ) {
    }

    ngOnInit() {
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item)
        this.DataService.get_all_contacts().subscribe(data=>{
            this.all_contacts = data;
            this.all_contacts = this.all_contacts.body;
            this.all_contacts.forEach(element=>{
                if (element.type == 'OCF') {
                    this.ocf = element;
                } else if (element.type == 'CSF'){
                    this.csf = element;
                }
            })
        })
    }
}
