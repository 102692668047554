import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { GetDataService } from "../get-data.service";
import { IpServiceService } from "../ip-service.service";
import { LanguageService } from "../language.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Loader, LoaderOptions } from "google-maps";

@Component({
  selector: "app-outlets",
  templateUrl: "./outlets.component.html",
  styleUrls: ["./outlets.component.css"],
  //    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutletsComponent implements OnInit {

    @ViewChild('map', null ) mapDiv:ElementRef;

    map;
    areas = ['AllLocal', 'HKI', 'KLN', 'NT'];
    ipAddress:string;
    subscription:Subscription;
    lang;
    all_outlets;
    all_brands;
    specific_outlet_info;
    specific_location;
    brandList =[
        {
          "image":null,
          "description":"All Shops",
          "value": "All"
        },{
          "image":"../../assets/img/outlets/Image 1.png",
          "description":"Italian Tomato",
          "value": "IT"
        },{
          "image":"../../assets/img/outlets/Image 2.png",
          "description":"Italian Tomato Café",
          "value": "IC"
      },{
          "image":"../../assets/img/outlets/Image 3.png",
          "description":"Fari-beurre",
          "value": "FB"
      },{
          "image":"../../assets/img/outlets/Group 1.png",
          "description":"Cafe & Grill",
          "value": "CG"
      },{
          "image":"../../assets/img/outlets/Image 5.png",
          "description":"夢見屋",
          "value": "KY"
      }
    ];
  selectedBrand = [ "All" ];
  selectedLocation = ['AllLocal'];
  selectedOutlets;

    getBrandLogo_alt(id) {
        return this.DataService.getRoot() + '/brand_logos_alt/' + id + '.png'
    }
    
    options: LoaderOptions = {};
 //   loader = new Loader();
    loader = new Loader('AIzaSyApa7zfxdiyc6By9oV1E3pknOoIGXe6u5U', this.options);
    google = this.loader.load();

    constructor( private DataService: GetDataService, public LangService: LanguageService, private translate: TranslateService, public sanitizer: DomSanitizer,private ip:IpServiceService ) { }

    ngOnInit() {
        this.DataService.all_shop().subscribe(data => {
            this.all_outlets = data;
            this.all_outlets = this.all_outlets.body;
            this.selectedOutlets = this.all_outlets;
            this.reinitMap();
        });
        this.DataService.get_all_brands().subscribe(data => {
            this.all_brands = data;
        });
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item);
        this.DataService.page_visited({ page_visited: 'outlets' }).subscribe();
    }
    ngOnDestroy() {
        // prevent memory leak when component is destroyed
        this.subscription.unsubscribe();
    }

    onBrandChange( value ){
      if( value == "All"){
        this.selectedBrand = ["All"];
      }else{
        if(this.selectedBrand.includes(value)){
          this.selectedBrand = this.selectedBrand.filter(item => item !== value);
          if(this.selectedBrand.length == 0) this.selectedBrand = ["All"];
        }else{
          if(this.selectedBrand.includes("All"))
            this.selectedBrand = [value];
          else
            this.selectedBrand.push(value);
        }
      };
      
      if( this.selectedBrand.includes("All"))
        value = ["IT", "IC", "FB", "CG", "KY"];
      else
        value = this.selectedBrand;

        this.DataService.all_shop_byBrand({ search_terms: value })
                        .subscribe( (data) => {
                          this.all_outlets = data;
                          this.all_outlets = this.all_outlets.body;
                          this.onLocationChange(null);
                        });
    }

    onLocationChange( value ){
      if(value){
        if( value == "AllLocal")
          this.selectedLocation = ["AllLocal"];
        else{
          if(this.selectedLocation.includes(value)){
            this.selectedLocation = this.selectedLocation.filter(item => item !== value);
            if(this.selectedLocation.length == 0) this.selectedLocation = ["AllLocal"];
          }else{
            if(this.selectedLocation.includes("AllLocal"))
              this.selectedLocation = [value];
            else
              this.selectedLocation.push(value);
          }
        }
      };

      this.selectedOutlets = [];

      this.selectedLocation.forEach( location => {
        if(location !== "AllLocal"){
          this.selectedOutlets = this.selectedOutlets.concat(this.all_outlets.filter( outlet => outlet.area == location));
        }else{
          this.selectedOutlets = this.all_outlets;
      }});

      this.reinitMap();
    }

    reinitMap(){
        this.map = new google.maps.Map(this.mapDiv.nativeElement, {
          center: { lat: 22.339118, lng: 114.187198 },
          zoom: 11,
        });

        for( var shop of this.selectedOutlets ){
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(
              shop.coordinates[0],
              shop.coordinates[1]
            ),
            map: this.map,
          });

          var shop_name = shop.shop_name[this.lang];
          var brand_name = shop.brand[this.lang];
          var content_body = `
            <h5>${brand_name} - ${shop_name}</h5>
            <br>
            <p>${shop.address.en}
            <br>
            <p> Tel: ${shop.phone_num}
          `;

          const infowindow = new google.maps.InfoWindow({
            content: content_body,
          });

          marker.addListener("click", () => {
            infowindow.close();
            this.map.setZoom(15);
            infowindow.open(marker.getMap(), marker);
          });
          
          marker.setMap(this.map);
        }
    }

    getShopInfo(value) {
      this.specific_outlet_info = this.selectedOutlets.find((o) => o.shop_id === value);
      if (!this.specific_outlet_info.map_link || this.specific_outlet_info.map_link == undefined|| this.specific_outlet_info.map_link.length == 0) {
        this.specific_location =
          "https://maps.google.com/maps?q=" +
          this.specific_outlet_info.coordinates[0] +
          "," +
          this.specific_outlet_info.coordinates[1] +
          "&t=&z=18&ie=UTF8&iwloc=&output=embed";
      } else {
          this.specific_location = this.specific_outlet_info.map_link;
      }
    }
}