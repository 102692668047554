import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppConfig } from './app.config';

@Injectable({
    providedIn: 'root'
})
export class GetDataService {


    myconfig;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'my-auth-token'
        })
    }

    constructor( private http: HttpClient, private config: AppConfig) { 
        this.myconfig = this.getConfig();
    }

    getConfig() {
        if (typeof(this.myconfig) == "undefined") {
            let server_root_url_website:string = this.config.getConfig('server_root_url_website');
            let server_root_url_cms:string = this.config.getConfig('server_root_url_cms');
            let graphics_root_url_website:string = this.config.getConfig('graphics_root_url_website');
            let graphics_root_url_cms:string = this.config.getConfig('graphics_root_url_cms');
            
            let mysetting = {};
            mysetting["server_root_url_website"] = server_root_url_website; // "/api/";
            // mysetting["server_root_url_website"] = "https://www.italiantomato.com.hk/api//";
            mysetting["server_root_url_cms"] = server_root_url_cms; //"/api/";
            mysetting["graphics_root_url_website"] = graphics_root_url_website; // "/photos";
            mysetting["graphics_root_url_cms"] = graphics_root_url_cms; // "/photos";
            this.myconfig = mysetting;
        }
        return this.myconfig;
    }
    
    getRoot() {
        return this.getConfig()["graphics_root_url_website"];
    }

    get_all_contents() {
        return this.http.get( this.getConfig()["server_root_url_website"]  + '/get-all-webContent');
    }

    get_all_brands() {
        return this.http.get( this.getConfig()["server_root_url_website"]   + '/get-all-active-brands',  this.httpOptions );
    }

    update_specific_brands(brand) {
        return this.http.post( this.getConfig()["server_root_url_website"]   + '/update-brands', brand, this.httpOptions );
    }

    get_all_jobs() {
        return this.http.get( this.getConfig()["server_root_url_website"]  + '/jobs/get-all', this.httpOptions );
    }

    get_specific_cat_jobs(category) {
        return this.http.post( this.getConfig()["server_root_url_website"]  + '/jobs/get-specific-category', category, this.httpOptions );
    }

    get_all_active_news() {
        return this.http.get( this.getConfig()["server_root_url_website"]  + '/latest/get-all-active-news', this.httpOptions );
    }

    get_all_contacts(){
        return this.http.get( this.getConfig()["server_root_url_website"]   + '/contacts/get-all', this.httpOptions );
    }

    all_shop() {
        return this.http.get( this.getConfig()["server_root_url_website"]   + '/outlets/get-all-active-shops', this.httpOptions );
    }

    all_shop_byBrand(query) {
        return this.http.post( this.getConfig()["server_root_url_website"]  + '/outlets/get-all-active-shops-byBrand', query, this.httpOptions );
    }

    add_shop(shop) {
        return this.http.post( this.getConfig()["server_root_url_website"]   + '/outlets/add-shop', shop, this.httpOptions );
    }

    update_shop(shop) {
        return this.http.post( this.getConfig()["server_root_url_website"]   + '/outlets/update-shop', shop, this.httpOptions );
    }

    delete_shop(shop) {
        return this.http.post( this.getConfig()["server_root_url_website"]   + '/outlets/delete-shop', shop, this.httpOptions );
    }

    // Membership Benefits related--
    get_all_tier() {
        return this.http.get( this.getConfig()["server_root_url_website"]  + '/membership/get-all-tier', this.httpOptions );
    }

    // Banners related--
    get_all_banners() {
        return this.http.get( this.getConfig()["server_root_url_website"]  + '/banners/get-all', this.httpOptions );
    }

    // Promotion related--
    get_all_promotions() {
        return this.http.get( this.getConfig()["server_root_url_website"]  + '/promotion/get-active', this.httpOptions );
    }   

    //    content related--
    get_pp() {
        return this.http.get( this.getConfig()["server_root_url_website"]  + '/webContent/get-pp',  this.httpOptions );
    };

    get_tnc() {
        return this.http.get( this.getConfig()["server_root_url_website"]   + '/webContent/get-tnc',  this.httpOptions );
    };

    // Record related--
    page_visited(record) {
        return this.http.post( this.getConfig()["server_root_url_website"]  + '/visitors/add-record', record, this.httpOptions );    
    }

    poster_clicked(record) {
        return this.http.post( this.getConfig()["server_root_url_website"]  + '/visitors/add-posters-record', record, this.httpOptions );    
    }
    

}
