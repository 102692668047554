import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruit-others',
  templateUrl: './recruit-others.component.html',
  styleUrls: ['./recruit-others.component.css']
})
export class RecruitOthersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
