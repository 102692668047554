import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
	name: 'searchResultSort',
	pure: true
})
export class SearchResultSortPipe implements PipeTransform {
	transform(items: any[], sortedBy: string): any {
		console.log('sortedBy', sortedBy);

		return items.sort((a, b) => {return b[sortedBy] - a[sortedBy]});
	}
}

@Pipe({
	name: 'filter',
	pure: true
})
export class FilterPipe implements PipeTransform {
	transform(items: any[], terms): any {
		var filtered = [];
		if ( !terms ) return items;
		if ( items != undefined ) {
			terms.forEach( t =>{
				var filtered_terms = items.filter(function (e) {
					return e.area == t;
				});
				filtered = filtered.concat(filtered_terms)
			});
			return filtered;
		}
	}
}

@Pipe({
	name: 'sortBy',
	pure: true
})
export class SortByPipe implements PipeTransform {

	transform(value: any[], order = '', column: string = ''): any[]{
		if (!value || order === '' || !order) { return value; } // no array
		if (value.length <= 1) { return value; } // array with only one item
		if (!column || column === '') { 
			if(order==='asc'){return value.sort()}
			else{return value.sort().reverse();}
		} // sort 1d array
		return orderBy(value, [column], [order]);
	}

}
