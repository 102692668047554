import { Component, OnInit } from '@angular/core';
import { GetDataService } from '../get-data.service';

@Component({
  selector: 'app-recruit-office',
  templateUrl: './recruit-office.component.html',
  styleUrls: ['./recruit-office.component.css']
})
export class RecruitOfficeComponent implements OnInit {

  constructor( private DataService: GetDataService ) { }
  
  all_office_jobs;
  
  ngOnInit() {
    this.DataService.get_specific_cat_jobs({category_id: 'OFFICE'}).subscribe( data => {  
      this.all_office_jobs = data;
    })
  }

}
