import { Component, OnInit } from '@angular/core';
import { LanguageService} from '../language.service';
import { Subscription } from 'rxjs';
import { GetDataService} from '../get-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-brands',
    templateUrl: './brands.component.html',
    styleUrls: ['./brands.component.css']
})

export class BrandsComponent implements OnInit {

    subscription:Subscription;

    all_contents;
    all_brands;
    lang;

    constructor( private DataService: GetDataService, public LangService: LanguageService, private translate: TranslateService ) { }

    ngOnInit() {
        this.DataService.get_all_contents().subscribe( data => {
            this.all_contents = data;
        });
        this.DataService.get_all_brands().subscribe(data => {
            this.all_brands = data;
        });
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item)
    }
    
    getBrandLogo(id) {
        return this.DataService.getRoot() + '/brands_logos/' + id + '.jpg'
    }
    
    getBrandPhoto(id) {
        return this.DataService.getRoot() + '/brand_photos/' + id + '.jpg'
    }
    
    getFoodMenu(code) {
        return this.DataService.getRoot() + '/food_menu/' + code
    }
    
    getSpecialFoodMenu(code) {
        return this.DataService.getRoot() + '/special_menu/' + code
    }
}
