import { Component, OnInit } from '@angular/core';
import { GetDataService } from '../get-data.service';
import { IpServiceService } from '../ip-service.service';
import { LanguageService} from '../language.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-join-us',
    templateUrl: './join-us.component.html',
    styleUrls: ['./join-us.component.css']
})
export class JoinUsComponent implements OnInit {

    all_benefits;
    basic_benefits;
    vip_benefits;

    subscription:Subscription;
    lang;
    
    constructor( private DataService: GetDataService, public LangService: LanguageService, private translate: TranslateService,  private ip:IpServiceService ) { }

    ngOnInit() {
        this.DataService.get_all_tier().subscribe(data => {
            this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item)
            this.all_benefits = data;
            this.all_benefits = this.all_benefits.body
            this.all_benefits.forEach( element => {
                if( element.tier_id == 'b0f4e4f0-19b0-11eb-9e9d-03ac8d986b61'){
                    this.basic_benefits = element;
                }
                if ( element.tier_id == '64019620-1cc6-11eb-ae5d-93b430da6b9f') {
                    this.vip_benefits = element;
                }
            })
        });
    }

}
