import { Component, OnInit } from '@angular/core';
import { GetDataService } from '../get-data.service';

@Component({
    selector: 'app-recruit-restrnt',
    templateUrl: './recruit-restrnt.component.html',
    styleUrls: ['./recruit-restrnt.component.css']
})
export class RecruitRestrntComponent implements OnInit {

    constructor( private DataService: GetDataService ) { }

    all_restaurants_jobs;

    ngOnInit() {
        this.DataService.get_specific_cat_jobs({category_id: 'RESTRT'}).subscribe( data => {  
            this.all_restaurants_jobs = data;
        })
    }

}
