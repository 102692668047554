import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { Pipe, PipeTransform } from '@angular/core';
import { NgxPaginationModule} from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { NewsComponent } from './news/news.component';
import { LatestComponent } from './latest/latest.component';
import { BrandsComponent } from './brands/brands.component';
import { OutletsComponent } from './outlets/outlets.component';
import { OrderingComponent } from './ordering/ordering.component';
import { JoinUsComponent } from './join-us/join-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TAndCComponent } from './t-and-c/t-and-c.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RecruitmentComponent } from './recruitment/recruitment.component';
import { SearchResultSortPipe, FilterPipe, SortByPipe } from './search-result-sort.pipe';
import { RecruitRestrntComponent } from './recruit-restrnt/recruit-restrnt.component';
import { RecruitCakeshopComponent } from './recruit-cakeshop/recruit-cakeshop.component';
import { RecruitFactoryComponent } from './recruit-factory/recruit-factory.component';
import { RecruitOfficeComponent } from './recruit-office/recruit-office.component';
import { RecruitOthersComponent } from './recruit-others/recruit-others.component';
import { APP_INITIALIZER } from '@angular/core';
import { AppConfig }       from './app.config';

const appRoutes: Routes = [
    { path: 'home', component: HomepageComponent}, //data: {animation: 'HomePage'} 
    { path: 'latest', component: LatestComponent, data: {animation: 'restaurantsPage'} },
    { path: 'brands', component: BrandsComponent, data: {animation: 'aboutPage'}  },
    { path: 'outlets', component: OutletsComponent, data: {animation: 'newsPage'}  },
    { path: 'ordering', component: OrderingComponent, data: {animation: 'suppleChainPage'} },
    { path: 'joinUs', component: JoinUsComponent, data: {animation: 'contactPage'}  },
    { path: 'contactUs', component: ContactUsComponent, data: {animation: 'eventPage'}  },
    { path: 'tandc', component: TAndCComponent, data: {animation: 'tandcPage'}  },
    { path: 'pp', component: PrivacyPolicyComponent, data: {animation: 'ppPage'}  },
    { path: 'recruit', component: RecruitmentComponent, data: {animation: 'recruitPage'}  },
    { path: 'recruitRest', component: RecruitRestrntComponent, data: {animation: 'recruitRestPage'}  },
    { path: 'recruitCake', component: RecruitCakeshopComponent, data: {animation: 'recruitCakePage'}  },
    { path: 'recruitFact', component: RecruitFactoryComponent, data: {animation: 'recruitFactPage'}  },
    { path: 'recruitOffice', component: RecruitOfficeComponent, data: {animation: 'recruitOfficePage'}  },
    { path: 'recruitOther', component: RecruitOthersComponent, data: {animation: 'recruitOtherPage'}  },
    { path: '',
     redirectTo: '/home',
     pathMatch: 'full'
    },
    { path: '**', component: HomepageComponent }
];


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http ,'./assets/i18n/', '.json');
}

    @NgModule({
        declarations: [
            AppComponent,
            HomepageComponent,
            NewsComponent,
            LatestComponent,
            BrandsComponent,
            OutletsComponent,
            OrderingComponent,
            JoinUsComponent,
            ContactUsComponent,
            TAndCComponent,
            PrivacyPolicyComponent,
            RecruitmentComponent,
            SearchResultSortPipe,
            FilterPipe,
            SortByPipe,
            RecruitRestrntComponent,
            RecruitCakeshopComponent,
            RecruitFactoryComponent,
            RecruitOfficeComponent,
            RecruitOthersComponent
        ],
        imports: [
            RouterModule.forRoot(
                appRoutes,
                { useHash: true, scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }
            ),
            BrowserModule,
            NgxPaginationModule,
            FormsModule,
            ReactiveFormsModule,
            HttpClientModule,
            AppRoutingModule,
            BrowserAnimationsModule,
            TranslateModule.forRoot({
                defaultLanguage: 'en',
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            }),  
        ],
        exports: [
            TranslateModule
        ],
        providers: [
            AppConfig,
            { provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true }
        ],
        bootstrap: [AppComponent]
    })
export class AppModule { }
