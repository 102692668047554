import { Component, OnInit } from '@angular/core';
import { GetDataService } from '../get-data.service';
import { IpServiceService } from '../ip-service.service';
import { LanguageService} from '../language.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
    
    promotion: any;
    all_brands;
    ipAddress:string;
    subscription:Subscription;
    lang;
    all_banners;
    web_banners;
    fb_banners;

    constructor( private DataService: GetDataService, public LangService: LanguageService, private translate: TranslateService,  private ip:IpServiceService ) { }


    getBrandPhoto(id) {
        return this.DataService.getRoot() + '/brand_photos/' + id + '.jpg'
    }

    getBrandLogo(id) {
        return this.DataService.getRoot() + '/brands_logos/' + id + '.jpg'
    }

    getWebBanner(id) {
        return this.DataService.getRoot() + '/banner_web/' + id + '.jpg'
    }

    getFbBanner(id) {
        return this.DataService.getRoot() + '/banner_fb/' + id + '.jpg'
    }

    ngOnInit() {
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item)
        this.DataService.get_all_brands().subscribe( data => {  
            this.all_brands = data;
        });
        this.ip.getIPAddress().subscribe((res:any)=>{  
            this.ipAddress=res.ip;
        });
        this.DataService.page_visited( { page_visited: 'home'} ).subscribe(data=>{

        })
        this.DataService.get_all_banners().subscribe(data=>{
            this.all_banners = data;
            this.all_banners = this.all_banners.body;
            this.all_banners.forEach(element => {
                if (element.banner_location == 'banner_web') {
                    this.web_banners = element.banners;
                }
                if (element.banner_location == 'banner_fb') {
                    this.fb_banners = element.banners;
                }
            })
        })
        
        this.DataService.get_all_promotions().subscribe(data=>{
            this.promotion = data;
            this.promotion = this.promotion.body;
            if (this.promotion) {
                if(this.promotion.status == true ) {
                    document.getElementById("promoBtn").click();
                } 
            }
        })
    }

    getPromoPhoto(id) {
        return this.DataService.getRoot() + '/promotion/' + id + '.jpg'
    }

}
