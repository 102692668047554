import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { LanguageService} from '../language.service';
import { Subscription } from 'rxjs';
import { GetDataService} from '../get-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-latest',
    templateUrl: './latest.component.html',
    styleUrls: ['./latest.component.css']
})
export class LatestComponent implements OnInit {

    subscription:Subscription;
    lang;
    pdf;

    constructor( private DataService: GetDataService, public LangService: LanguageService, private translate: TranslateService, private sanitizer:DomSanitizer ) { }

    all_news;
    specific_news_info;    

    // for pagination
    p: number = 1;

    resultPage: Boolean = false;

    ngOnInit() {
        this.DataService.get_all_active_news().subscribe( data => {  
            this.all_news = data;
        })
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item);
        this.DataService.page_visited( { page_visited: 'latest'} ).subscribe(data=>{

        })
        
    }
    ngOnDestroy() {
        // prevent memory leak when component is destroyed
        this.subscription.unsubscribe();
    }

    getSafeUrl(link) {
        if ( link == "" || link == undefined ) {
            return false;
        } else {
            return this.sanitizer.bypassSecurityTrustResourceUrl(link);
        }
    }


    getNewsInfo(id) {
        this.specific_news_info = this.all_news.find(n => n.news_id === id);
        var recordInfo = {
            page_visited: 'latest',
            posters_clicked: id,
        }
        this.DataService.poster_clicked( recordInfo ).subscribe();
    }

    getLatestNewsPhotos(id) {
        return this.DataService.getRoot() + '/latest_news/' + id;
    }
	
	getSpecialPhoto(link){
        if ( link == "" || link == undefined || link.split('/')[1] == 'pdf') {
            return false;
        } else {
            return this.sanitizer.bypassSecurityTrustResourceUrl(this.DataService.getRoot() + '/latest_news_content_photos/' + link)
        }
    }
	
    getSpecialPDF(link){
        if ( link == "" || link == undefined || link.split('/')[1] != 'pdf') {
            return false;
        } else {
            return this.sanitizer.bypassSecurityTrustResourceUrl(this.DataService.getRoot() + '/latest_news_content_photos/' + link)
        }
    }

}
