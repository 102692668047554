import { Component, OnInit } from '@angular/core';
import { GetDataService } from '../get-data.service';

@Component({
  selector: 'app-recruit-factory',
  templateUrl: './recruit-factory.component.html',
  styleUrls: ['./recruit-factory.component.css']
})
export class RecruitFactoryComponent implements OnInit {

  constructor( private DataService: GetDataService ) { }
  
  all_factory_jobs;
  
  ngOnInit() {
    this.DataService.get_specific_cat_jobs({category_id: 'FACTRY'}).subscribe( data => {  
      this.all_factory_jobs = data;
    })
  }
}
